// change the theme
$theme-colors: (
  "primary": #770049,
  "secondary": #e93721,
  "danger": #f3040a,
  "light": #d4dbe0,
  "dark": #020001,
  "success": #0ca057,
  "warning": #e4c90c,
  "info": #fc4039,
);
$input-focus-border-color: rgb(100, 204, 197);
$input-focus-box-shadow: 0 0 0 0.2rem rgba(100, 204, 197, 0.25);
$breadcrumb-bg: rgba(26, 57, 80, 0.3);
$breadcrumb-border-radius: 0.375rem;
$breadcrumb-padding-x: 10px;
$breadcrumb-padding-y: 10px;
$breadcrumb-divider-color: #770049;
$form-label-color: #939185;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "NotoSansLao";
  src: local("NotoSansLao"),
    url(./fonts/NotoSansLao/NotoSansLaoUI-Regular.ttf) format("truetype");
}
html {
  min-height: calc(100% + env(safe-area-inset-top));
  // padding: env(safe-area-inset-top) env(safe-area-inset-right)
  //   env(safe-area-inset-bottom) env(safe-area-inset-left);
  //   background-color: #770049;
}

*,
body,
.Dialogbox,
label,
div {
  margin: 0;
  box-sizing: border-box;
  font-family: "NotoSansLao";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NotoSansLao", Arial, Helvetica, sans-serif;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  /* font-size: 15px; */
}
body {
  @apply min-h-screen select-none bg-zinc-50 text-zinc-900 antialiased p-safe dark:bg-zinc-900 dark:text-zinc-50;

  /* PWA enhancements */
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;

  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

body {
  width: 100%;
  overflow: hidden;
  height: 100dvh;
  position: fixed;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: #770049;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#google_translate_element {
  width: 300px;
  float: right;
  text-align: right;
  display: block;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}
// #content {
//   min-height: 100vh;
//   padding-bottom: 54px;
// }

p {
  margin-bottom: 0;
}
